export const twitterShareLink = (url) => ({
  pathname: `https://twitter.com/intent/tweet`,
  query: {
    text: url,
  },
});

export const linkedInShareLink = (url) => ({
  pathname: `https://www.linkedin.com/shareArticle`,
  query: {
    mini: true,
    url: url,
  },
});

export const facebookShareLink = (url) => ({
  pathname: `https://www.facebook.com/sharer/sharer.php`,
  query: {
    u: url,
  },
});
