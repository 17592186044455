import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useLocalStorage } from 'react-use';

import Link from '@components/Link';

import siteInfo from '@content/default.json';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './HomepageHero.module.css';

import interactionHooks from '@util/interactions';

import ArrowRightIcon from '@icons/ArrowRight.svg';
import Button from '@components/Button';
import Icon from '@components/Icon';
import TextRenderer from '@components/TextRenderer';
import Illustration from '@components/Illustration';
import { switchPagesKey } from '@constants/personalization';
import { ClientLogoMarquee } from '@components/Marquee';

export const HomepageHero = ({
  claim,
  subline,
  cta = 'Talk to sales',
  features,
  clientLogos = [],
  theme = 'dark',
  personalization = [],
}) => {
  const openChat = interactionHooks.openChat();

  const [switchPageValue] = useLocalStorage(switchPagesKey);
  const [labels, setLabels] = useState({
    claim,
    subline,
    cta,
    personalized: false,
  });

  useEffect(() => {
    const personalizedLabels = personalization.find(
      (p) => p.switchPageSlug === switchPageValue,
    );
    setLabels({
      personalized: true,
      ...labels,
      ...personalizedLabels,
    });
  }, []);

  return (
    <div
      className={cn(styles.root, {
        [styles.themeLight]: theme === 'light',
        [styles.themeDark]: theme === 'dark',
      })}
      style={{
        '--section-marker-color': 'var(--color-accent-default)',
      }}
    >
      <div className={cn(styles.hero)}>
        <div className={cn(styles.innerWrapper)}>
          <div className={cn(styles.illustration)}>
            <Illustration illustration="Homepage" />
          </div>
          <div className={cn(styles.textWrapper)}>
            <TextRenderer className={cn(styles.claim)} text={labels.claim} />
            <TextRenderer
              className={cn(styles.subline)}
              text={labels.subline}
            />

            <div className={cn(styles.actions)}>
              <div className={styles.action}>
                <Button
                  size="xlarge"
                  href={siteInfo.signUpUrl}
                  trackingScope={
                    labels.personalized ? 'Personalised Hero' : 'Hero'
                  }
                  personalisedTopic={switchPageValue}
                  fullWidth
                  isStartFreeTrial
                >
                  Start your free trial
                </Button>
              </div>
              <div className={styles.action}>
                <Button
                  fullWidth
                  trackingScope={
                    labels.personalized ? 'Personalised Hero' : 'Hero'
                  }
                  personalisedTopic={switchPageValue}
                  variant="secondary"
                  colorScheme={theme === 'light' ? 'dark' : 'light'}
                  size="xlarge"
                  onClick={openChat}
                >
                  {labels.cta}
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.clientLogos}>
            <ClientLogoMarquee clientLogos={clientLogos} />
          </div>
        </div>
      </div>

      <div className={styles.keyFeaturesWrapper}>
        <div className={cn(styles.keyFeatures)}>
          {features.map((feature, index) => (
            <Link
              prefetch={false}
              key={index}
              className={cn(styles.keyFeature)}
              href={feature.href}
            >
              <div className={cn(styles.keyFeatureIconWrapper)}>
                <Icon
                  className={cn(styles.keyFeatureIcon)}
                  icon={feature.icon}
                />
              </div>
              <div className={styles.keyFeatureBody}>
                <div
                  className={cn(
                    textStyles.headline3,
                    styles.keyFeatureHeadline,
                  )}
                >
                  {feature.headline}
                </div>
                <div className={cn(styles.keyFeatureText)}>{feature.text}</div>
                <div className={styles.keyFeatureLink}>
                  <span className={styles.keyFeatureLinkText}>
                    {feature.cta ?? 'Learn more'}
                  </span>
                  <ArrowRightIcon className={cn(styles.keyFeatureLinkIcon)} />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
