import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from '@components/Link';

import {
  twitterShareLink,
  linkedInShareLink,
  facebookShareLink,
} from '@util/socialMedia';

import TwitterIcon from '@icons/socialMedia/twitter.svg';
import LinkedInIcon from '@icons/socialMedia/linkedin.svg';
import FacebookIcon from '@icons/socialMedia/facebook.svg';

import * as styles from './SocialShare.module.css';

const SocialShare = ({ className, fullPath }) => {
  return (
    <nav className={cn(styles.root, className)}>
      <span className={styles.shareLabel}>Share</span>
      <Link
        aria-label="Share on Twitter"
        className={styles.shareLink}
        href={twitterShareLink(fullPath)}
        target="_blank"
      >
        <TwitterIcon className={styles.shareIcon} />
      </Link>

      <Link
        aria-label="Share on LinkedIn"
        className={styles.shareLink}
        href={linkedInShareLink(fullPath)}
        target="_blank"
      >
        <LinkedInIcon className={styles.shareIcon} />
      </Link>
      <Link
        aria-label="Share on Facebook"
        className={styles.shareLink}
        href={facebookShareLink(fullPath)}
        target="_blank"
      >
        <FacebookIcon className={styles.shareIcon} />
      </Link>
    </nav>
  );
};

SocialShare.propTypes = {
  className: PropTypes.string,
  fullPath: PropTypes.string.isRequired,
};

export default SocialShare;
