import cn from 'classnames';

import { colorMap } from '@constants/colors';

import TextRenderer from '@components/TextRenderer';

import Gradient from './Gradient';

import textStyles from '@styles/textStyles.module.css';

import * as styles from './TertiaryHero.module.css';

export const TertiaryHero = ({
  eyebrow,
  heading,
  markerColor,
  theme = null,
  gradient = 'teal',
  flipGradient = false,
  unobtrusive = false,
}) => {
  const HeadlineTag = 'h1';

  const rootClassNames = cn(styles.root, {
    [styles.noTheme]: !theme,
    [styles.themeLight]: theme === 'light',
    [styles.themeDark]: theme === 'dark',
    [styles.flipGradient]: flipGradient,
    [styles.unobtrusive]: unobtrusive,
  });

  return (
    <section
      className={rootClassNames}
      style={{
        '--section-marker-color': markerColor && colorMap[markerColor],
      }}
    >
      <div className={styles.headerContainer}>
        {theme && gradient && (
          <div className={styles.gradient} aria-hidden>
            <Gradient fill={gradient} />
          </div>
        )}

        <div className={styles.header}>
          {eyebrow && <p className={cn(textStyles.eyebrow)}>{eyebrow}</p>}
          <TextRenderer
            text={heading}
            as={HeadlineTag}
            className={cn(styles.headline, textStyles.headlineXL)}
          />
        </div>
      </div>
    </section>
  );
};
