import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TickList from '@components/TickList';

import * as styles from './Form.module.css';
import TextRenderer from '@components/TextRenderer';

const Form = ({ className, form, listItems, instructions }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          ...form,
          target: '#hubspotForm',
          cssRequired: '',
          cssClass: styles.form,
          errorClass: styles.inputError,
          errorMessageClass: styles.inputErrorMessages,
        });
      }
    });

    // Clean up
    return () => {
      document.body.removeChild(script);
      const formEl = document.getElementById('hubspotForm');
      if (formEl) {
        formEl.innerHTML = '';
      }
    };
  }, [form]);

  return (
    <div className={cn(styles.root, className)}>
      {instructions && (
        <TextRenderer className={cn(styles.instructions)} text={instructions} />
      )}
      {listItems && <TickList className={styles.tickList} items={listItems} />}
      <div id="hubspotForm" />
    </div>
  );
};

Form.propTypes = {
  props: PropTypes.object,
};

export default Form;
