import React from 'react';
import cn from 'classnames';

import { colorMap } from '@constants/colors';

import Illustration from '@components/Illustration';
import Gradient from '../Gradient';
import Logo from '@components/Logo';

import * as styles from './ResourceMockup.module.css';

const TITLE_FONT_SIZE = 45;

const ResourceMockup = ({ resource }) => {
  const {
    resourceTitle,
    resourceIllustration = 'NavigatorTeaser',
    resourceIllustrationColor = null,
    resourceGradient,
  } = resource;

  const illustrationBgColor = colorMap[resourceIllustrationColor];
  const titleLines = resourceTitle.split('\n');

  return (
    <div aria-hidden="true" className={cn(styles.root)}>
      {resourceGradient && (
        <div className={styles.gradient} aria-hidden>
          <Gradient fill={resourceGradient} />
        </div>
      )}
      <div className={styles.file}>
        <div className={styles.fileTitle}>
          <svg viewBox="0 0 400 400">
            {titleLines.map((line, i) => (
              <text
                key={i}
                x={0}
                y={(i + 1) * TITLE_FONT_SIZE}
                fontFamily="var(--font-family-headline)"
                fontSize={TITLE_FONT_SIZE}
              >
                {line}
              </text>
            ))}
          </svg>
        </div>
        <Logo className={styles.fileLogo} />
        <div
          className={styles.fileIllustration}
          style={{ backgroundColor: illustrationBgColor }}
        >
          <Illustration
            illustration={resourceIllustration}
            className={styles.illustration}
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceMockup;
