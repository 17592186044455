import { usePathname } from 'next/navigation';

import cn from 'classnames';

import { colorMap } from '@constants/colors';
import siteInformation from '@content/default.json';

import { readableDate } from '@util/date';

import TextRenderer from '@components/TextRenderer';
import SocialShare from '@components/SocialShare';
import Link from '@components/Link';
import Gradient from './Gradient';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './BlogArticleHero.module.css';

const { blogPath } = siteInformation;

export const BlogArticleHero = ({
  heading,
  markerColor,
  theme = null,
  gradient = 'teal',
  flipGradient = false,
  blogData,
}) => {
  const pathname = usePathname();
  const { primaryTagName, primaryTagSlug, publishedAt, readingTime } = blogData;
  const HeadlineTag = 'h1';

  const rootClassNames = cn(styles.root, {
    [styles.noTheme]: !theme,
    [styles.themeLight]: theme === 'light',
    [styles.themeDark]: theme === 'dark',
    [styles.flipGradient]: flipGradient,
  });

  const fullPath = `${siteInformation.url}${pathname}`;

  return (
    <section
      className={rootClassNames}
      style={{
        '--section-marker-color': markerColor && colorMap[markerColor],
      }}
    >
      <div className={styles.headerContainer}>
        {theme && gradient && (
          <div className={styles.gradient} aria-hidden>
            <Gradient fill={gradient} />
          </div>
        )}

        <div className={styles.header}>
          <p className={cn(textStyles.eyebrow)}>Blog</p>
          <TextRenderer
            text={heading}
            as={HeadlineTag}
            className={cn(styles.headline, textStyles.headlineXL)}
          />
          <div className={styles.supplemental}>
            <div className={styles.meta}>
              <span>{readableDate(publishedAt)}</span>
              <span className={styles.slash}>/</span>
              {primaryTagName && (
                <>
                  <span className={styles.tag}>
                    <Link href={`${blogPath}/tag/${primaryTagSlug}`}>
                      {primaryTagName}
                    </Link>
                  </span>
                  <span className={styles.slash}>/</span>
                </>
              )}
              {readingTime} min read
            </div>
            <SocialShare fullPath={fullPath} />
          </div>
        </div>
      </div>
    </section>
  );
};
