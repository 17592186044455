import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import useStateMachine from '@cassiozen/usestatemachine';

import Link from '@components/Link';
import Button from '@components/Button';
import Icon from '@components/Icon';
import TextRenderer from '@components/TextRenderer';
import TickList from '@components/TickList';
import { TextInput, CheckboxInput } from '@components/Inputs';

import { trackAnalyticsEvent } from '@util/analytics';

import campaignsFormContent from '@content/campaigns.json';
import siteInformation from '@content/default.json';

import * as styles from './Form.module.css';
import { HoneyPotInput } from '@components/Inputs/Inputs';

const HeroForm = ({
  name,
  className,
  form,
  referrer,
  listItems,
  saveData = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      referrer,
    },
  });

  const [contactName, setContactName] = useState(null);

  const {
    campaignsForm: { fields, error, success },
  } = campaignsFormContent;

  const {
    instructions,
    hasTextarea = false,
    nameInputLabel = fields.name.label,
    nameInputPlaceholder = fields.name.placeholder,
    nameInputRequired = fields.name.validation.required,
    emailInputLabel = fields.email.label,
    emailInputPlaceholder = fields.email.placeholder,
    emailInputRequired = fields.email.validation.required,
    emailInputValidation = fields.email.validation.valid,
    textareaLabel = fields.message.label,
    textareaPlaceholder = fields.message.placeholder,
    /* eslint-disable-next-line */
    textareaRequired = fields.message.validation.required,
    checkboxLabel = fields.agree.label,
    checkboxRequired = fields.agree.validation.required,
    submitLabel = fields.submit.label,
  } = form;

  const [formState, send] = useStateMachine({
    initial: 'idle',
    states: {
      idle: {
        on: { SUBMIT: 'sending' },
      },
      sending: {
        on: { FAIL: 'error', DONE: 'success' },
        effect({ event, send }) {
          setContactName(event.data.name);
          saveData(
            { data: event.data, name },
            {
              resolve: () => send('DONE'),
              reject: () => send('FAIL'),
            },
          );
        },
      },
      error: {
        effect() {
          trackAnalyticsEvent('formSubmissionFailure');
        },
      },
      success: {
        effect() {
          trackAnalyticsEvent('formSubmissionSuccess');
        },
      },
    },
  });

  const isSending = useMemo(() => formState.value === 'sending', [formState]);
  const showSuccess = useMemo(() => formState.value === 'success', [formState]);
  const showError = useMemo(() => formState.value === 'error', [formState]);

  return (
    <div className={cn(styles.root, className)}>
      {instructions && (
        <TextRenderer className={cn(styles.instructions)} text={instructions} />
      )}

      {listItems && <TickList className={styles.tickList} items={listItems} />}

      <form
        className={cn(styles.form)}
        onSubmit={handleSubmit((data) => {
          send({ type: 'SUBMIT', data });
        })}
      >
        <div className={cn(styles.inputs)}>
          <TextInput
            label={nameInputLabel}
            placeholder={nameInputPlaceholder}
            errors={errors.name}
            {...register('name', {
              required: nameInputRequired,
            })}
          />

          <TextInput
            label={emailInputLabel}
            placeholder={emailInputPlaceholder}
            errors={errors.email}
            {...register('email', {
              required: emailInputRequired,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: emailInputValidation,
              },
            })}
          />
          {hasTextarea && (
            <TextInput
              multiline
              label={textareaLabel}
              placeholder={textareaPlaceholder}
              errors={errors.message}
              rows={4}
              {...register('message')}
            />
          )}

          <CheckboxInput
            label={checkboxLabel}
            errors={errors.agree}
            {...register('agree', {
              required: checkboxRequired,
            })}
          />

          <input type="hidden" {...register('referrer')} />
          <HoneyPotInput register={register} />
        </div>

        <div className={cn(styles.actions)}>
          <Button disabled={isSending} type="submit" trackingScope={name}>
            {isSending ? 'Sending' : submitLabel}
          </Button>
        </div>
      </form>
      {showError && (
        <div role="alert" className={cn(styles.overlay)}>
          <Icon icon="stop" className={cn(styles.overlayIcon)} />
          <div className={cn(styles.overlayText)}>
            <p>{error.title}</p>
            <p>
              {error.fallback}{' '}
              <Link
                href={`mailto: ${siteInformation.salesEmail}`}
                className={cn(styles.fallbackLink)}
              >
                {siteInformation.salesEmail}
              </Link>
            </p>
          </div>
        </div>
      )}
      {showSuccess && (
        <div className={cn(styles.overlay)}>
          <Icon icon="success" className={cn(styles.overlayIcon)} />
          <div className={cn(styles.overlayText)}>
            {success.titleUpToName} {contactName}.{' '}
            <span>{success.subline}</span>
          </div>
        </div>
      )}
    </div>
  );
};

HeroForm.propTypes = {
  props: PropTypes.object,
};

export default HeroForm;
