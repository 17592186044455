import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import useStateMachine from '@cassiozen/usestatemachine';

import campaignsFormContent from '@content/campaigns.json';

import Button from '@components/Button';
import Icon from '@components/Icon';
import { TextInput } from '@components/Inputs';

import { trackAnalyticsEvent } from '@util/analytics';

import * as styles from './Form.module.css';
import { HoneyPotInput } from '@components/Inputs/Inputs';

const HeroResourceForm = ({
  name,
  resourceUrl,
  referrer,
  ctaLabel,
  saveData = () => {},
}) => {
  const {
    campaignsForm: { fields, error, success },
  } = campaignsFormContent;

  const [contactEmail, setContactEmail] = useState(null);

  const [formState, send] = useStateMachine({
    initial: 'idle',
    states: {
      idle: {
        on: { SUBMIT: 'sending' },
      },
      sending: {
        on: { FAIL: 'error', DONE: 'success' },
        effect({ event, send }) {
          setContactEmail(event.data.email);
          saveData(
            { data: event.data, name },
            {
              resolve: () => send('DONE'),
              reject: () => send('FAIL'),
            },
          );
        },
      },
      error: {
        effect() {
          trackAnalyticsEvent('formSubmissionFailure');
        },
      },
      success: {
        effect() {
          trackAnalyticsEvent('formSubmissionSuccess');
        },
      },
    },
  });

  const isSending = useMemo(() => formState.value === 'sending', [formState]);
  const showSuccess = useMemo(() => formState.value === 'success', [formState]);
  const showError = useMemo(() => formState.value === 'error', [formState]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: { resourceUrl, referrer },
  });

  return (
    <div className={cn(styles.root)}>
      {!showSuccess && !showError && (
        <form
          onSubmit={handleSubmit((data) => {
            send({ type: 'SUBMIT', data });
          })}
        >
          <div className={cn(styles.inputWrapper)}>
            <TextInput
              inputClassName={cn(styles.textInput)}
              placeholder={fields.email.label}
              errors={errors.email}
              {...register('email', {
                required: fields.email.validation.required,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: fields.email.validation.valid,
                },
              })}
            />

            <input type="hidden" {...register('resourceUrl')} />
            <input type="hidden" {...register('referrer')} />
            <HoneyPotInput register={register} />

            <div className={cn(styles.actions)}>
              <Button disabled={isSending} type="submit" trackingScope={name}>
                {isSending ? 'Sending' : ctaLabel}
              </Button>
            </div>
          </div>
        </form>
      )}
      {showSuccess && (
        <div className={cn(styles.result, styles.success)}>
          <Icon icon="success" className={styles.icon} />
          <span>
            {success.resourceUpToEmail} {contactEmail}
          </span>
        </div>
      )}
      {showError && (
        <div className={cn(styles.result)}>
          <Icon icon="stop" className={styles.icon} />
          <span>{error.title}</span>
        </div>
      )}
    </div>
  );
};

export default HeroResourceForm;
