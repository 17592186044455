import React from 'react';
import cn from 'classnames';

import StaggerAnimateIn from '@components/StaggerAnimateIn';
import Icon from '@components/Icon';
import Illustration from '@components/Illustration';
import TextRenderer from '@components/TextRenderer';

import { colorMap } from '@constants/colors';

import Gradient from './Gradient';
import textStyles from '@styles/textStyles.module.css';
import styles from './MarketingHero.module.css';
import ActionButtons from './ActionButtons';
import { ClientLogoMarquee } from '@components/Marquee';
import SanityImage from '@components/SanityImage';
import { isSafari } from '@util/browser';

const CLIENT_LOGO_MAX_WIDTH = 170;
const CLIENT_LOGO_MAX_HEIGHT = 60;

export const MarketingHero = ({
  actions = [],
  clientLogos,
  featureShowcase,
  flipGradient,
  gradient,
  heading,
  highlightColor,
  illustration,
  markerColor,
  subline,
  theme,
}) => {
  const HeadlineTag = 'h2';

  return (
    <div
      className={cn(styles.root, {
        [styles.flipGradient]: flipGradient,
      })}
      style={{
        '--section-marker-color': markerColor && colorMap[markerColor],
        '--hero-highlight-color': highlightColor && colorMap[highlightColor],
      }}
      data-theme={theme}
    >
      <div className={styles.wrapper}>
        {gradient && (
          <div className={styles.gradient} aria-hidden>
            <Gradient fill={gradient} />
          </div>
        )}
        {illustration && (
          <div className={styles.illustration}>
            <Illustration illustration={illustration} />
          </div>
        )}

        <div className={styles.inner}>
          <div className={styles.upper}>
            <div className={styles.textWrapper}>
              <TextRenderer
                text={heading}
                as={HeadlineTag}
                className={styles.headline}
              />

              <TextRenderer
                text={subline}
                as={HeadlineTag}
                className={styles.subline}
              />

              <ActionButtons
                actions={actions}
                theme={theme}
                trackingScope="MarketingHero"
              />
            </div>

            <div className={styles.media}>
              <div className={styles.featureShowcase}>
                <h3 className={textStyles.eyebrow}>{featureShowcase.title}</h3>
                <ol className={styles.featureShowcaseList}>
                  <StaggerAnimateIn>
                    {featureShowcase.list.map((item, index) => (
                      <li key={index} className={styles.featureShowcaseItem}>
                        <Icon
                          className={styles.featureShowcaseItemIcon}
                          icon={item.icon}
                        />
                        <div>
                          <TextRenderer
                            className={styles.featureShowcaseItemAfter}
                            text={item.feature}
                            as="div"
                          />
                          <TextRenderer
                            className={styles.featureShowcaseItemBefore}
                            text={item.subline}
                            as="div"
                          />
                        </div>
                      </li>
                    ))}
                  </StaggerAnimateIn>
                </ol>
              </div>
            </div>
          </div>

          {Array.isArray(clientLogos) && (
            <aside
              className={cn(
                styles.clientLogos,
                clientLogos.length > 9 && styles.bleed,
              )}
            >
              <h3 className={textStyles.eyebrow}>
                Trusted by industry leaders
              </h3>
              {clientLogos.length > 9 && (
                <ClientLogoMarquee
                  clientLogos={clientLogos}
                  imgClassName={styles.clientLogo}
                />
              )}

              {clientLogos.length < 5 && (
                <StaggerAnimateIn
                  className={styles.clientLogoGrid}
                  childrenClassName={styles.clientLogoGridItem}
                  initialDelay={0.125}
                >
                  {clientLogos.map((logo, index) => (
                    <SanityImage
                      key={index}
                      priority={isSafari()}
                      asset={logo.image.asset}
                      alt={logo.alt}
                      maxWidth={CLIENT_LOGO_MAX_WIDTH}
                      maxHeight={CLIENT_LOGO_MAX_HEIGHT}
                      className={styles.clientLogo}
                      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
                      placeholder="blur"
                    />
                  ))}
                </StaggerAnimateIn>
              )}
            </aside>
          )}
        </div>
      </div>
    </div>
  );
};
