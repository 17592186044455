import cn from 'classnames';

import TextRenderer from '@components/TextRenderer';

import { BasePrimaryHero } from '../PrimaryHero';
import Form from './Form';

import AnimateIn from '@components/AnimateIn';
import * as styles from './CampaignHeroForm.module.css';
import textStyles from '@styles/textStyles.module.css';
import { submitRoute } from '@util/routes';

export const CampaignHeroWithForm = ({
  name,
  theme,
  eyebrow,
  heading,
  backgroundIllustration,
  gradient,
  listItems,
  markerColor,
  text,
  form,
}) => {
  const referrer = typeof document !== 'undefined' ? document.referrer : '';

  const handleFormSubmit = async ({ data, name }, { reject, resolve }) => {
    try {
      const res = await fetch(submitRoute(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          formName: 'campaign',
          campaignName: name,
          ...data,
        }),
      });

      if (res && res.status === 202) {
        resolve();
      } else {
        reject();
      }
    } catch (e) {
      reject();
    }
  };

  const textElement = (
    <TextRenderer
      text={text}
      as="p"
      className={cn(styles.text, textStyles.bodyXL)}
    />
  );

  return (
    <BasePrimaryHero
      name={name}
      eyebrow={eyebrow}
      heading={heading}
      text={textElement}
      backgroundIllustration={backgroundIllustration}
      gradient={gradient}
      theme={theme}
      markerColor={markerColor}
      className={styles.hero}
      mediaClassName={styles.formMedia}
      headingSize="xlarge"
    >
      <div className={styles.form}>
        <AnimateIn>
          <Form
            name={name}
            referrer={referrer}
            form={form}
            saveData={handleFormSubmit}
            listItems={listItems}
          />
        </AnimateIn>
      </div>
    </BasePrimaryHero>
  );
};
