import React from 'react';
import cn from 'classnames';

import StaggerAnimateIn from '@components/StaggerAnimateIn';

import SimpleGradient from './SimpleGradient';
import { BaseSecondaryHero, SecondaryHeroCallToAction } from './SecondaryHero';

import styles from './SwitchHero.module.css';
import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';

export const SwitchHero = ({
  actions,
  eyebrow,
  heading,
  illustration,
  markerColor,
  gradient,
  text,
  flipGradient,
  claim,
}) => {
  const media = (
    <div className={cn(styles.callToAction)}>
      <div className={cn(styles.callToActionIllustration)}>
        <SimpleGradient />
      </div>
      <StaggerAnimateIn>
        <div className={textStyles.headlineL}>{claim}</div>
        <SecondaryHeroCallToAction actions={actions} />
      </StaggerAnimateIn>
    </div>
  );

  return (
    <BaseSecondaryHero
      eyebrow={eyebrow}
      heading={heading}
      headingSize="small"
      illustration={illustration}
      markerColor={markerColor}
      theme="light"
      gradient={gradient}
      flipGradient={flipGradient}
      media={media}
      mediaClassName={styles.media}
      minHeight="450px"
      minOverlap="var(--space-l)"
    >
      {text && (
        <p className={(textStyles.bodyM, measureStyles.medium, styles.intro)}>
          {text}
        </p>
      )}
    </BaseSecondaryHero>
  );
};
